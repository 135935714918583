import { Content, Employed, IItem } from '@/Models'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import http from '@/services'
import authModule from '@/store/auth'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class MultiInputNew extends Vue {
  @Prop({ required: true })
  index?: number
  @Prop({ required: true })
  content?: Content
  @Prop({ required: false })
  isResources?: boolean

  resources: Array<IItem> = []

  mounted() {
    if (this.isResources) this.getResources()
  }

  async getResources() {
    const res = await http.get('panel/employee/')
    if (!res.data) throw new Error(res.data)
    this.resources = res.data.map((x: Employed) => ({
      value: x.id,
      text: x.first_name + ' ' + x.middle_name + ' ' + x.last_name,
    }))
  }

  remove() {
    this.$emit('deleteInput', { index: this.index })
  }
}
